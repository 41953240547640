import React, { useState, useEffect } from 'react';

import { DishItem } from 'models/entities/dish-item';
import { Model } from './index.model';

import { useAuth } from 'views/services/auth';
import { useMemory } from 'views/services/memory';
import { useModal, EditDishItemTarget, ImportDishItemsTarget, ExportDishItemsTarget, ModalResult } from 'views/services/modal';
import { useBusinessCode, useMenuCategoryCode } from 'views/services/hooks';
import { showUnit, showPercentage, showMoney } from 'views/services/helpers';
import { InterfaceLoading } from 'views/components/interface-loading';
import { NoInterfaceData } from 'views/components/no-interface-data';
import { Header } from 'views/components/header';
import { Root, Frame, Categories, Items, ItemHeader, ItemFooter, Item, Ingredients } from './index.styled';

const DishItems = () => {

  const auth = useAuth();
  const memory = useMemory();
  const businessCode = useBusinessCode();
  const menuCategoryCode = useMenuCategoryCode();
  const modal = useModal();
  const [model, setModel] = useState(new Model());

  function edit(item?: DishItem) {
    if (!model.business) throw new Error('business is undefined');
    const dishItem = model.buildEditor(item);
    const currency = model.business.currency;
    const target = new EditDishItemTarget({ dishItem, currency });
    const { ingredientItems, business, ingredientCategories } = model;
    const other = { ingredientItems, business, ingredientCategories };
    const tell = apply;
    modal.open('edit-dish-item', { target, strict: true, other, tell });
  }

  function apply(result: ModalResult) {
    if (!result.target) return;
    if (!(result.target instanceof EditDishItemTarget)) return;
    const dishItem = result.target.dishItem.original;
    const ingredientItem = result.target.dishItem.ingredientItem?.original;
    switch (result.state) {
      case 'created': setModel(model.add({ dishItem, ingredientItem })); break;
      case 'updated': setModel(model.replace({ dishItem, ingredientItem })); break;
      case 'updated-created': setModel(model.replaceAndAdd({ dishItem, ingredientItem })); break;
      case 'updated-updated': setModel(model.replace({ dishItem, ingredientItem })); break;
      case 'updated-deleted': setModel(model.replaceAndRemove({ dishItem, ingredientItem })); break;
      case 'deleted': setModel(model.remove({ dishItem, ingredientItem })); break;
    }
  }

  function importData() {
    const target = new ImportDishItemsTarget();
    const other = { menuCategory: model.menuCategory };
    const tell = applyItems;
    modal.open('import-dish-items', { target, strict: true, other, tell });
  }

  function applyItems(result: ModalResult) {
    if (!result.target) return;
    if (!(result.target instanceof ImportDishItemsTarget)) return;
    const target = result.target;
    switch (result.state) {
      case 'imported': setModel(model.addItems(target)); break;
    }
  }

  function exportData() {
    const target = new ExportDishItemsTarget({ dishItems: model.list });
    modal.open('export-dish-items', { target });
  }

  function exportCategories() {
    const target = model.menuCategory?.dishCategories;
    modal.open('export-dish-categories', { target });
  }

  function watchAuth() {
    if (!auth.ok) return;
    if (!businessCode) return;
    const businessScope = memory.get('business-scope');
    businessScope ? setModel(model.init(businessScope)) : model.readBusiness(businessCode).then(data => setModel(it => it.init(data)));
  }

  function watchBusiness() {
    if (!model.business) return;
    if (!menuCategoryCode) return;
    model.read(menuCategoryCode).then(data => setModel(it => it.init(data)));
    model.readOther().then(data => setModel(it => it.init(data)));
  }

  function watchCollection() {
    if (!model.collection) return;
    if (!model.collection.nextToken) return;
    model.readAllCollection().then(data => setModel(it => it.init(data)));
  }

  function watchIngredientItems() {
    if (!model.ingredientItems) return;
    if (!model.ingredientItems.nextToken) return;
    model.readAllIngredientItems().then(data => setModel(it => it.init(data)));
  }

  function watchIngredientCategories() {
    if (!model.ingredientCategories) return;
    if (!model.ingredientCategories.nextToken) return;
    model.readAllIngredientCategories().then(data => setModel(it => it.init(data)));
  }

  useEffect(watchAuth, [auth.ok, businessCode]);
  useEffect(watchBusiness, [model.business, menuCategoryCode]);
  useEffect(watchCollection, [model.collection]);
  useEffect(watchIngredientItems, [model.ingredientItems]);
  useEffect(watchIngredientCategories, [model.ingredientCategories]);

  return (
    <Root>
      {auth.user.admin ? (
        <Header mode={'business'} businesses={model.businesses} business={model.business} onCreate={() => edit()} disabledAction={model.disabledAction} onImport={() => importData()} onExport={() => exportData()} onExportCategories={() => exportCategories()} />
      ) : (
        <Header mode={'business'} businesses={model.businesses} business={model.business} onCreate={() => edit()} disabledAction={model.disabledAction} />
      )}
      {!model.collection && <InterfaceLoading />}
      {model.list && (
        <Frame>
          {(!model.list.length || !model.menuCategory?.dishCategories.documents.length) ? <NoInterfaceData>No Dish Item</NoInterfaceData> : (
            <>
              <Categories columnNumber={model.menuCategory.dishCategories.documents.length}>
                <ol>
                  {model.menuCategory.dishCategories.documents.map((it, i) => (
                    <li key={i} className={it.original.offMenu ? 'off-menu' : ''}>
                      <h2>{it.original.name}</h2>
                    </li>
                  ))}
                </ol>
              </Categories>
              <Items columnNumber={model.menuCategory.dishCategories.documents.length}>
                <ol>
                  {model.menuCategory.dishCategories.documents.map((a, i) => (
                    <li key={i}>
                      {model.list!.filter(b => b.category.id === a.id).map((c, j) => (
                        <Item key={j} onClick={() => edit(c)} className={c.ingredientItemId ? 'ingredient-item' : ''}>
                          <ItemHeader>
                            <h3>{c.name}</h3>
                            <div>{c.posItemId ? `POS : ${c.posItemId}` : ''}</div>
                          </ItemHeader>
                          <Ingredients>
                            <table>
                              <thead>
                              <tr>
                                <th>Ingredients</th>
                                <th className="center">Unit</th>
                                <th className="center">Gross Price</th>
                                <th className="center">Usage</th>
                                <th className="center">Cost</th>
                              </tr>
                              </thead>
                              <tbody>
                              {c.ingredients.documents.map((d, k) => (
                                <tr key={k}>
                                  <td className="text">{d.item.name}</td>
                                  <td className="right">{showUnit(d.item.unitValue, d.item.unitSymbol)}</td>
                                  <td className="number">{showMoney(d.item.grossPrice, model.business?.currency)}</td>
                                  <td className="number">{d.usage}</td>
                                  <td className="number">{showMoney(d.cost, model.business?.currency)}</td>
                                </tr>
                              ))}
                              {!c.ingredients.documents.length && <tr><td colSpan={5} className="none">No ingredients</td></tr>}
                              </tbody>
                            </table>
                          </Ingredients>
                          <ItemFooter>
                            <table>
                              <thead>
                              <tr>
                                <th>Price</th>
                                <th>Cost Rate</th>
                                <th>Cost</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr>
                                <td>{showMoney(c.price, model.business?.currency)}</td>
                                <td>{showPercentage(c.costRate * 100)} %</td>
                                <td>{showMoney(c.cost, model.business?.currency)}</td>
                              </tr>
                              </tbody>
                            </table>
                          </ItemFooter>
                        </Item>
                      ))}
                    </li>
                  ))}
                </ol>
              </Items>
            </>
          )}
        </Frame>
      )}
    </Root>
  );

};

export default DishItems;