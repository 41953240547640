import { DishItem } from 'models/entities/dish-item';

class Condition {

  constructor() {
  }

  filter(all: DishItem[]): DishItem[] {
    return this.sort(all.filter(it => this.check(it)));
  }

  sort(all: DishItem[]): DishItem[] {
    const byOrder = (a: DishItem, b: DishItem) => {
      switch (true) {
        case a.order.toString() < b.order.toString(): return -1;
        case a.order.toString() > b.order.toString(): return 1;
        case a.order.toString() === b.order.toString() && a.name < b.name: return -1;
        case a.order.toString() === b.order.toString() && a.name > b.name: return 1;
        default: return 0;
      }
    };
    return all.sort(byOrder);
  }

  private check(item: DishItem): boolean {
    return true;
  }

}

export { Condition };