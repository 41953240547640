import { Api } from 'models/services/api';
import { ReadIngredientItemsQuery, ReadIngredientCategoriesQuery } from 'models/services/graphql';
import { IngredientItemCollectionData } from 'models/entities/ingredient-item';
import { IngredientCategoryCollectionData } from 'models/entities/ingredient-category';

type DataToRead = {
  businessId: string;
};

const RequestQuery = `
  query MenuCategory($businessId: ID!, $nextToken: String) {
    ${ReadIngredientItemsQuery}
    ${ReadIngredientCategoriesQuery}
  }
`;

type ResponseData = {
  data: {
    readIngredientItems: IngredientItemCollectionData;
    readIngredientCategories: IngredientCategoryCollectionData;
  }
}

type Result = {
  ingredientItems: IngredientItemCollectionData;
  ingredientCategories: IngredientCategoryCollectionData;
};

class ReadGql {

  readonly result?: Result;
  private readonly api: Api<ResponseData>;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(data: DataToRead): Promise<this> {
    const api = await this.api.fetch({ variables: data });
    if (!api.response) throw new Error('invalid response');
    const ingredientItems = api.response.data.readIngredientItems;
    const ingredientCategories = api.response.data.readIngredientCategories;
    const result = { ingredientItems, ingredientCategories };
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result, api });
  }

}

export { ReadGql };