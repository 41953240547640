import { DishItem, DishItemCollection, DishItemEditor } from 'models/entities/dish-item';
import { Business, BusinessCollection } from 'models/entities/business';
import { MenuCategory } from 'models/entities/menu-category';
import { IngredientItemCollection, InHouseIngredientItem } from 'models/entities/ingredient-item';
import { IngredientCategoryCollection } from 'models/entities/ingredient-category';

import { Condition } from './models/condition';
import { ReadBusinessGql } from '../index.model';
import { ReadGql } from './models/read-gql';

type DataToInit = {
  collection?: DishItemCollection;
  list?: DishItem[];
  business?: Business;
  menuCategory?: MenuCategory;
  businesses?: BusinessCollection;
  ingredientItems?: IngredientItemCollection;
  ingredientCategories?: IngredientCategoryCollection;
  disabledAction?: {
    onCreate: boolean;
    onImport: boolean;
    onExport: boolean;
    onExportCategories: boolean;
  };
};

type DataToProcess = {
  dishItem: DishItem;
  ingredientItem?: InHouseIngredientItem;
};

type BatchDataToProcess = {
  dishItems: DishItem[];
};

type DataToApply = {
  collection: DishItemCollection;
  ingredientItems?: IngredientItemCollection;
};

class Model {

  readonly condition: Condition;
  readonly collection?: DishItemCollection;
  readonly list?: DishItem[];
  readonly business?: Business;
  readonly menuCategory?: MenuCategory;
  readonly businesses?: BusinessCollection;
  readonly ingredientItems?: IngredientItemCollection;
  readonly ingredientCategories?: IngredientCategoryCollection;
  readonly disabledAction: {
    onCreate: boolean;
    onImport: boolean;
    onExport: boolean;
    onExportCategories: boolean;
  };

  constructor() {
    this.condition = new Condition();
    this.disabledAction = {
      onCreate: false,
      onImport: false,
      onExport: false,
      onExportCategories: false,
    };
  }

  init(data: DataToInit): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data });
  }

  async readBusiness(code: string): Promise<DataToInit> {
    const gql = await new ReadBusinessGql().fetch({ code });
    const { business, businesses } = gql;
    return { business, businesses };
  }

  async read(code: string): Promise<DataToInit> {
    if (!this.business) throw new Error('business is undefined');
    const menuCategory = this.business.menuCategories.documents.find(it => it.original.code === code)!;
    const collection = await DishItemCollection.read(menuCategory.id!);
    const list = this.condition.filter(collection.documents);
    const disabledAction = {
      onCreate: !menuCategory.dishCategories.documents.length,
      onImport: !menuCategory.dishCategories.documents.length,
      onExport: !list.length,
      onExportCategories: !menuCategory.dishCategories.documents.length,
    };
    return { collection, list, menuCategory, disabledAction };
  }

  async readOther(): Promise<DataToInit> {
    if (!this.business) throw new Error('business is undefined');
    const gql = await new ReadGql().fetch({ businessId: this.business.id! });
    if (!gql.result) throw new Error('invalid result');
    const ingredientItems = new IngredientItemCollection(this.business.id, gql.result.ingredientItems);
    const ingredientCategories = new IngredientCategoryCollection(this.business.id, gql.result.ingredientCategories);
    return { ingredientItems, ingredientCategories };
  }

  async readAllCollection(): Promise<DataToInit> {
    if (!this.collection) throw new Error('collection is undefined');
    const collection = await this.collection.readAll();
    const list = this.condition.filter(collection.documents);
    return { collection, list };
  }

  async readAllIngredientItems(): Promise<DataToInit> {
    if (!this.ingredientItems) throw new Error('ingredientItems is undefined');
    const ingredientItems = await this.ingredientItems.readAll();
    return { ingredientItems };
  }

  async readAllIngredientCategories(): Promise<DataToInit> {
    if (!this.ingredientCategories) throw new Error('ingredientCategories is undefined');
    const ingredientCategories = await this.ingredientCategories.readAll();
    return { ingredientCategories };
  }

  build(): DishItem {
    if (!this.collection) throw new Error('collection is undefined');
    if (!this.menuCategory) throw new Error('menuCategory is undefined');
    const menuCategory = this.menuCategory;
    const category = menuCategory.dishCategories.documents[0];
    const items = this.collection.documents.filter(it => it.category.id === category.id);
    return DishItem.buildNext(items, { menuCategoryId: menuCategory.id!, category });
  }

  buildEditor(item?: DishItem): DishItemEditor {
    if (!this.menuCategory?.dishCategories) throw new Error('categories are undefined');
    const original = item ?? this.build();
    const categories = this.menuCategory.dishCategories;
    return new DishItemEditor({ original, categories });
  }

  add(data: DataToProcess): this {
    if (!this.collection) throw new Error('collection is undefined');
    if (!this.ingredientItems) throw new Error('ingredientItems is undefined');
    const { dishItem, ingredientItem } = data;
    const collection = this.collection.add(dishItem);
    const ingredientItems = ingredientItem ? this.ingredientItems.add(ingredientItem) : this.ingredientItems;
    return this.apply({ collection, ingredientItems });
  }

  addItems(data: BatchDataToProcess): this {
    if (!this.collection) throw new Error('collection is undefined');
    const collection = this.collection.addBatch(data.dishItems);
    return this.apply({ collection });
  }

  replaceAndAdd(data: DataToProcess): this {
    if (!this.collection) throw new Error('collection is undefined');
    if (!this.ingredientItems) throw new Error('ingredientItems is undefined');
    const { dishItem, ingredientItem } = data;
    const collection = this.collection.replace(dishItem);
    const ingredientItems = ingredientItem ? this.ingredientItems.add(ingredientItem) : this.ingredientItems;
    return this.apply({ collection, ingredientItems });
  }

  replace(data: DataToProcess): this {
    if (!this.collection) throw new Error('collection is undefined');
    if (!this.ingredientItems) throw new Error('ingredientItems is undefined');
    const { dishItem, ingredientItem } = data;
    const collection = this.collection.replace(dishItem, ingredientItem);
    const ingredientItems = ingredientItem ? this.ingredientItems.replace(ingredientItem) : this.ingredientItems;
    return this.apply({ collection, ingredientItems });
  }

  replaceAndRemove(data: DataToProcess): this {
    if (!this.collection) throw new Error('collection is undefined');
    if (!this.ingredientItems) throw new Error('ingredientItems is undefined');
    const { dishItem, ingredientItem } = data;
    const collection = this.collection.replace(dishItem);
    const ingredientItems = ingredientItem ? this.ingredientItems.remove(ingredientItem) : this.ingredientItems;
    return this.apply({ collection, ingredientItems });
  }

  remove(data: DataToProcess): this {
    if (!this.collection) throw new Error('collection is undefined');
    if (!this.ingredientItems) throw new Error('ingredientItems is undefined');
    const { dishItem, ingredientItem } = data;
    const collection = this.collection.remove(dishItem);
    const ingredientItems = ingredientItem ? this.ingredientItems.remove(ingredientItem) : this.ingredientItems;
    return this.apply({ collection, ingredientItems });
  }

  private apply(data: DataToApply): this {
    const props = { ...this, ...data };
    const list = this.condition.filter(props.collection.documents);
    const disabledAction = this.disabledAction;
    disabledAction.onExport = !list.length;
    return Object.assign(Object.create(this.constructor.prototype), { ...props, list, disabledAction });
  }

}

export { Model };